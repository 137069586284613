import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';

const LimbusDatabaseRyoshu: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="lc">
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company/">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/limbus-company/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Red Eyes vs W Corp Ryoshu</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/limbuscompany/categories/category_ryoshu.webp"
            alt="Combat mechanics"
          />
        </div>
        <div className="page-details">
          <h1>Red Eyes vs W Corp Ryoshu</h1>
          <h2>
            A guide that explains the differences between the two IDs and which
            one to use in Limbus Company.
          </h2>
          <p>
            Last updated: <strong>14/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          Note: For the purpose of this Guide, we will be abbreviating Red Eyes
          & Penitence Ryoshu to RE Ryoshu and W Corp Ryoshu to W Ryoshu.
        </p>
        <p>
          RE Ryoshu and W Ryoshu are both very similar when it comes to their
          DPS capabilities, the only difference boiling down to utility and
          damage type. This guide will go over in-depth explaining that utility
          and help you choose which ID to use in that regard. So, let's get to
          it!
        </p>
        <SectionHeader title="RE Ryoshu's Utility and Pros Explained" />
        <p>
          The strongest feat RE Ryoshu has over W Corp is that both versions of
          her 3rd Skill - Skullbuster and Serious Skullbuster are essentially
          miniature Faust's Fluid Sac E.G.O in their HP/SP healing capabilities.
          Even during downtime after using Serious Skullbuster, she still has %
          based HP Heals and flat SP heals, which more often than not is the
          deciding factor on whether or not she gets used over W Ryoshu. Beyond
          that she has some additional factors making her stand out against W
          Ryoshu, they are:
        </p>
        <ul>
          <li>
            Her Passive increases damage with more stacks of "Red Eyes" and
            "Penitence", for up to 40% increase, whereas W Ryoshu's bonus damage
            for holding Charge Count is exclusive to her S1 and S2.
          </li>
          <li>
            Her Skullbuster provides more overall gains than W Ryoshu, giving up
            to 8 stacks of "Red Eyes" and 11 stacks of "Penitence", despite
            causing less damage than DDEDR (58 Base damage, assuming from 0
            stacks)
          </li>
          <li>
            Serious Skullbuster on other hand with its 128 Base damage, deals
            significantly more damage than DDEDR's 91 Base Damage (both will
            full conditionals), not to mention providing teamwide Heal on top.
          </li>
          <li>Gains SP Extremely fast thanks to "Penitence".</li>
          <li>
            Can fit comfortably into Charge Team or Bleed Team, assuming the
            team has enough Bleed Count inflictions to support that synergy as
            RE Ryoshu doesn't inflict Bleed Count herself.
          </li>
        </ul>
        <p>
          The primary drawback of RE Ryoshu is she loses ability to gain "Red
          Eyes" and "Penitence" for a single turn after Serious Skullbuster,
          although she can still heal allies on her skills.
        </p>
        <SectionHeader title="W Ryoshu's Utility and Pros Explained" />
        <p>
          What W Ryoshu has over RE Ryoshu is that her S2 remains as one of the
          strongest S2's in the game, all thanks to its ability to Inflict Slash
          Fragility and bonus damage on its 3rd coin if enemy is below certain
          HP threshold. Additionally, it also has a Pride skill, allowing W
          Ryoshu to fit into Pride-centric teams buffing her S2 even further.
          Beyond that she lacks any utility and has just really good damage
          output. W Ryoshu can also get Charges to fuel her S3 faster, albeit at
          cost of HP if you are under 15 Charge Count when you use the skill.
          You can get a partial refund on Charge Count if you manage to kill an
          enemy on DDEDR's last coin. Some factors to use her over RE Ryoshu
          are:
        </p>
        <ul>
          <li>
            Her S2 with all of its conditionals deals 63 Base damage, which in
            most cases would out damage RE Ryoshu's S3
          </li>
          <li>
            Her S2 can gain significant benefits from Pride-centric teams, just
            the Resonance alone gives it a lot of Offense Level, it also
            benefits from Captain Ishamael's S2 granting her further 2 Damage Up
            on top of 2 Pride Power Up.
          </li>
          <li>
            All of her skills feature +5 Offense Level across the board, which
            is more than all of RE Ryoshu's skills except Serious Skullbuster,
            which it is equal to.
          </li>
          <li>
            At cost of losing some HP, W Ryoshu can access her DDEDR
            significantly faster than RE Ryoshu can access Serious Skullbuster.
          </li>
        </ul>
        <SectionHeader title="Conclusion" />
        <p>
          As mentioned in the Foreword, W Ryoshu and RE Ryoshu go head-to-head
          in their capabilities, with W Ryoshu featuring consistently higher
          damage across the board, while RE Ryoshu sometimes falls behind her,
          sometimes pulls ahead, but has amazing healing capabilities and fits
          right into both a Charge Team and Bleed Team. Do keep in mind, W
          Ryoshu deals Slash damage, whereas RE Ryoshu deals Blunt, making them
          more effective in content weak to Slashes/Blunt respectively.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default LimbusDatabaseRyoshu;

export const Head: React.FC = () => (
  <Seo
    title="Red Eyes vs W Corp Ryoshu | Limbus Company | Prydwen Institute"
    description="A guide that explains the differences between the two IDs and which one to use in Limbus Company."
    game="limbus"
  />
);
